import { useDrupalUser } from '~/stores/drupalUser'
import { getLanguageFromPath } from '~/helpers/language'

/**
 * Redirects to the login page if the user is not logged in.
 */
export default defineNuxtRouteMiddleware((to) => {
  const drupalUser = useDrupalUser()

  if (!drupalUser.isLoggedIn) {
    return navigateTo({
      name: 'login' + getLanguageFromPath(to.path),
      query: { destination: to.path },
    })
  }
})
